<template>
    <section v-bind:class="['data-table', 'account-table']">
        <enso-table class="box is-paddingless raises-on-hover" id="accounts"
            v-on="$listeners"
            @impersonate="impersonate"
            @show="show">
            <template v-slot:avatar_id="{ row }">
                <div class="flex-centered">
                    <a class="figure-wrapper" @click="profile(row)">
                        <figure class="image is-24x24 avatar">
                            <img class="is-rounded"
                                v-bind:src="route('core.avatars.show', row.avatar_id)"
                                v-if="row.avatar_id">
                        </figure>
                    </a>
                </div>
            </template>
        </enso-table>
    </section>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMask } from '@fortawesome/free-solid-svg-icons';
import { EnsoTable } from '@enso-ui/tables/bulma';

library.add([faMask]);

export default {
    name: 'Index',

    components: { EnsoTable },

    inject: ['route'],

    methods: {
        impersonate(e) {
            this.$root.$emit('start-impersonating', e.id);
        },
        show(row) {
            this.$router.push({
                name: 'accounts.show',
                params: { account: row.id },
            });
        },
    },
};
</script>
