var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { class: ["data-table", "account-table"] },
    [
      _c(
        "enso-table",
        _vm._g(
          {
            staticClass: "box is-paddingless raises-on-hover",
            attrs: { id: "accounts" },
            on: { impersonate: _vm.impersonate, show: _vm.show },
            scopedSlots: _vm._u([
              {
                key: "avatar_id",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "flex-centered" }, [
                      _c(
                        "a",
                        {
                          staticClass: "figure-wrapper",
                          on: {
                            click: function($event) {
                              return _vm.profile(row)
                            }
                          }
                        },
                        [
                          _c(
                            "figure",
                            { staticClass: "image is-24x24 avatar" },
                            [
                              row.avatar_id
                                ? _c("img", {
                                    staticClass: "is-rounded",
                                    attrs: {
                                      src: _vm.route(
                                        "core.avatars.show",
                                        row.avatar_id
                                      )
                                    }
                                  })
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ])
                  ]
                }
              }
            ])
          },
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }